import { ApolloClient, ApolloClientOptions, createHttpLink } from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';

import { _getToken } from '../../helpers/authentication/authentication';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { isProduction } from '../../helpers/environment/isProduction';
import {
  buildGraphqlHeaders,
  GRAPHQL_URI,
  CORS_OPTIONS,
  BASS_OR_NOT_GRAPHQL_URI,
} from '../rest/shared';

import { cacheTypePolicies } from './cacheTypePolicies';
import { getRetryLink } from './shared';

type GraphQLClientType = 'rutilus' | 'bass-or-not';

const createHttpLinkForType = (type: GraphQLClientType) =>
  createHttpLink({
    uri: type === 'bass-or-not' ? BASS_OR_NOT_GRAPHQL_URI : GRAPHQL_URI,
    ...(type === 'bass-or-not' ? {} : CORS_OPTIONS),
  });

const authLink = setContext(async (_, context) => {
  const { headers, getAuthToken } = context;
  // The ideal situation is that the getAuthToken function should be passed in the context (
  // coming from useAuth), as it allows for the user's auth state to be updated correctly when the
  // the token is found to be invalid. That said, it's not always possible to do, for example if the
  // query is coming from outside the React render tree. For this case we fallback to using
  // `_getToken`.
  const asyncHeaders = await buildGraphqlHeaders(getAuthToken || _getToken);
  return { headers: { ...asyncHeaders, ...headers } };
});

const createApolloLink = (clientType: GraphQLClientType, useAuthLink: boolean) => {
  const httpLink = createHttpLinkForType(clientType);
  return useAuthLink
    ? authLink.concat(getRetryLink(clientType)).concat(httpLink)
    : getRetryLink(clientType).concat(httpLink);
};

export const initializeGraphqlClient = (
  options: Partial<ApolloClientOptions<NormalizedCacheObject>> = {},
  useAuthLink = true,
  clientType: GraphQLClientType = 'rutilus',
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    connectToDevTools: !isProduction() && isBrowser(),
    cache: new InMemoryCache(cacheTypePolicies),
    link: createApolloLink(clientType, useAuthLink),
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
    ...options,
  });
};
