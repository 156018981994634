import gql from 'graphql-tag';

import { ImageFragments } from './fragments/ImageType';
import { PageFragments } from './fragments/PageType';
import { ProductFragments } from './fragments/ProductType';
import { ProductUnitFragments } from './fragments/ProductUnitType';
import { UserFragments } from './fragments/UserType';

export const getCurrentUser = gql`
  query GetCurrentUser {
    currentUser: me {
      ...CurrentUserId
      ...CurrentUserAvatar
      legacyId: id
      createdAt
      isPremium
      isVerified
      firstName
      lastName
      nickname
      countryCode
      lengthUnit
      pressureUnit
      speedUnit
      temperatureUnit
      weightUnit
      premiumEndDate
      privateUuid
      privateFields {
        email
      }
    }
  }
  ${UserFragments.currentUserAvatar}
  ${UserFragments.currentUserId}
`;

export const getManageablePageStatuses = gql`
  query GetManageablePageStatuses {
    entry: me {
      ...CurrentUserId
      manageablePages(first: 50) {
        edges {
          node {
            ...PageId
            name
            published
          }
        }
      }
    }
  }
  ${PageFragments.id}
  ${UserFragments.currentUserId}
`;

export const getManageablePages = gql`
  query GetManageablePages {
    entry: me {
      ...CurrentUserId
      manageablePages(first: 50) {
        edges {
          node {
            ...PageId
            logo(width: 340, height: 340) {
              ...ImageFields
            }
            coverImages(width: 480, last: 1) {
              edges {
                node {
                  ...ImageFields
                }
              }
            }
            name
            published
            description
            promotedCallToActions(first: 1) {
              edges {
                node {
                  id
                  headline
                  subHeadline
                  targetUrl
                  buttonText
                  image {
                    ...ImageFields
                  }
                }
              }
            }
            pageable {
              ... on Brand {
                id
                externalId
                url
              }
            }
          }
        }
      }
    }
  }
  ${ImageFragments.imageFields}
  ${PageFragments.id}
  ${UserFragments.currentUserId}
`;

export const getPublishablePages = gql`
  query GetPublishablePages {
    entry: me {
      ...CurrentUserId
      pagesCanPublishAs(first: 20) {
        edges {
          node {
            ...PageId
            name
            externalId
          }
        }
      }
    }
  }
  ${PageFragments.id}
  ${UserFragments.currentUserId}
`;

export const getUserProductUnits = gql`
  query GetUserProductUnits($categoryId: Int!) {
    entry: me {
      ...CurrentUserId
      productUnits(first: 100, categoryId: $categoryId) {
        edges {
          node {
            ...ProductUnitId
            image(height: 96) {
              ...ImageFields
            }
            attributeValues(first: 3) {
              edges {
                node {
                  value {
                    name
                  }
                }
              }
            }
            product {
              __typename
              ...ProductId
              ...ProductBrandName
              displayName
            }
          }
        }
      }
    }
  }
  ${ImageFragments.imageFields}
  ${ProductFragments.brandName}
  ${ProductFragments.id}
  ${ProductUnitFragments.id}
  ${UserFragments.currentUserId}
`;

export const getConsents = gql`
  query GetConsents {
    me {
      ...CurrentUserId
      consents(last: 10) {
        edges {
          node {
            id
            accepted
            agreementUid
          }
        }
      }
    }
  }
  ${UserFragments.currentUserId}
`;
