import React, {
  useState,
  ReactNode,
  useEffect,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

import { useQuery, ApolloQueryResult } from '@apollo/client';

import { getAmplitudeInstance } from '../helpers/analytics/getAmplitudeInstance';
import { trackLoginAndAccountCreation } from '../helpers/analytics/trackLoginAndAccountCreation';
import {
  _forceTokenRefresh,
  _getToken,
  _isAuthenticated,
} from '../helpers/authentication/authentication';
import {
  destroyTokens,
  hasRefreshToken,
  _destroyAccessToken,
  _getAccessToken,
} from '../helpers/authentication/shared';
import { reportMykissError, setErrorReportingUser } from '../helpers/errorHandling';
import { GetCurrentUser, GetCurrentUser_currentUser } from '../interfaces/graphql';
import { getCurrentUser } from '../queries/rutilus/Me';

const INTERVAL_TOKEN_REFRESH_TIME_IN_MS = 500000;

const noOp = (): void => {};
const getTokenNoOp = async (): Promise<null> => Promise.resolve(null);
const refetchNoOp = async (): Promise<any> => Promise.resolve(undefined);
const checkForTokensNoOp = async (): Promise<any> => Promise.resolve(false);

export type IUser = GetCurrentUser_currentUser;
export type GetAuthToken = () => Promise<string | null>;
export type RefetchCurrentUser = () => Promise<ApolloQueryResult<GetCurrentUser>>;
export interface BassOrNotUser {
  id: string;
  position: number;
  rutilusExternalId: string;
  suggestionsCount: number;
}
export interface IAuthState {
  isLoggedIn: boolean;
  logout: () => void;
  currentUser: undefined | IUser;
  premiumEndDate: undefined | string;
  isLoadingUser: boolean;
  getToken: GetAuthToken;
  checkForTokens: () => Promise<boolean>;
  refetchCurrentUser: () => Promise<ApolloQueryResult<GetCurrentUser>>;
  invalidateAccessToken: () => string;
  setSignupMethod: Dispatch<SetStateAction<string | undefined>>;
}

const AuthContext = React.createContext<IAuthState>({
  isLoggedIn: false,
  logout: noOp,
  getToken: getTokenNoOp,
  checkForTokens: checkForTokensNoOp,
  currentUser: undefined,
  premiumEndDate: undefined,
  refetchCurrentUser: refetchNoOp,
  invalidateAccessToken: () => '',
  isLoadingUser: false,
  setSignupMethod: () => undefined,
});

export interface IAuthProviderProps {
  children: ReactNode;
  isAuthenticated: boolean;
  // Can be used for unit tests. If provided, all useEffects with mutations or data fetches will be
  // skipped at the values added to testState will override the context response.
  testState?: Partial<IAuthState>;
}

function AuthProvider({
  isAuthenticated: initialIsAuthenticated,
  testState,
  ...rest
}: IAuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(initialIsAuthenticated);
  const [analyticsEventSent, setAnalyticsEventSent] = useState(false);
  const [signupMethod, setSignupMethod] = useState<string>();
  const isTesting = !!testState;

  const getToken = async (): Promise<string | null> => {
    const token = _getToken();
    if (!token && isAuthenticated) {
      setIsAuthenticated(false);
    }
    return token;
  };
  // We don't use useRutilusQuery in order to avoid a dependency loop.
  const {
    data,
    refetch,
    error,
    loading: isLoadingUser,
  } = useQuery<GetCurrentUser>(getCurrentUser, {
    skip: !isAuthenticated || isTesting,
    context: { getAuthToken: getToken },
  });

  useEffect(() => {
    if (error) {
      reportMykissError(error);
    }
  }, [error]);

  const currentUser = data?.currentUser || undefined;
  const premiumEndDate = data?.currentUser?.premiumEndDate || undefined;

  useEffect(() => {
    if (currentUser) {
      getAmplitudeInstance()?.setUserId(currentUser.legacyId.toString());
      setErrorReportingUser(currentUser);

      if (!analyticsEventSent) {
        trackLoginAndAccountCreation(currentUser, signupMethod);
        setAnalyticsEventSent(true);
        setSignupMethod(undefined);
      }
    }
  }, [currentUser, analyticsEventSent, signupMethod]);

  /* check if this works with useCallback */
  const checkForTokens = useCallback(async (): Promise<boolean> => {
    if (isTesting) {
      return false;
    }
    return _isAuthenticated()
      .then(response => {
        setIsAuthenticated(response);
        return response;
      })
      .catch(e => {
        reportMykissError(e);
        return false;
      });
  }, [isTesting]);

  useEffect(() => {
    const tokenRefreshInterval = setInterval(() => {
      if (hasRefreshToken()) {
        _forceTokenRefresh().catch(reportMykissError);
      }
    }, INTERVAL_TOKEN_REFRESH_TIME_IN_MS);
    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, []);

  const logout = (): void => {
    destroyTokens();
    setIsAuthenticated(false);
  };

  const invalidateAccessToken = (): string => {
    const currentAccessToken = _getAccessToken();
    _destroyAccessToken();
    return currentAccessToken;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isAuthenticated,
        logout,
        checkForTokens,
        currentUser,
        premiumEndDate,
        refetchCurrentUser: refetch,
        getToken,
        invalidateAccessToken,
        isLoadingUser,
        setSignupMethod,
        ...(testState || {}),
      }}
      {...rest}
    />
  );
}

const useAuth = (): IAuthState => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
